import React from "react"
import PropTypes from "prop-types"

import Layout from "./../../../components/layout"
import SEO from "./../../../components/seo"

import Img from "gatsby-image"
import { Link, graphql, useStaticQuery } from "gatsby"
import ContactFormGrey from "../../../components/generic/contact-form-grey"

const SaveYourCompanyFromHackersAndDisastersCore = ({
  children,
  email,
  buttonText,
  ctaText,
  campaignId,
  campaignVariant,
  phoneIsVisible,
  emailIsVisible
}) => {
  const data = useStaticQuery(query)
  const specterDbaName = data.specterData.siteMetadata.company.name.dba
  const specterLegalName = data.specterData.siteMetadata.company.name.dba
  const specterLogo = data.specterLogo.childImageSharp.fluid
  const friendlyPic = data.friendlyPic.childImageSharp.fixed
  const reviewHeadshot = data.reviewHeadshot.childImageSharp.fixed

  return (
    <Layout landingPage={true}>
      <SEO
        title="Save your company from hackers and disasters!"
        description="Learn all about how we, as the an exclusive and premier IT managed service provider, can save you from hackers and disasters."
      />
      <section className="save-your-company-from-hackers-and-disasters-page__separator" />
      <nav className="save-your-company-from-hackers-and-disasters-page__nav">
        <div className="save-your-company-from-hackers-and-disasters-page__brand-image">
          <Link to="/">
            <Img
              title={specterDbaName + " logo"}
              alt={specterDbaName + " logo"}
              fluid={specterLogo}
            />
          </Link>
        </div>
      </nav>
      <header className="save-your-company-from-hackers-and-disasters-page">
        <div className="save-your-company-from-hackers-and-disasters-page__content">
          <div className="save-your-company-from-hackers-and-disasters-page__header">
            <div className="save-your-company-from-hackers-and-disasters-page__header-text">
              <h1 className="heading-primary save-your-company-from-hackers-and-disasters-page__heading-primary">
                Security can <em>make or break your business</em>. We prepare
                you to <em>grow and succeed securely</em>.
              </h1>
              <ul className="save-your-company-from-hackers-and-disasters-page__list">
                <li>
                  <span>
                    <em>50%</em> of small-to-medium sized &nbsp;
                    <em>business will be hacked!</em>
                  </span>
                </li>
                <li>
                  <span>
                    <em>60% of those hacked</em> businesses will be &nbsp;
                    <em>out of business in 6 months</em>!
                  </span>
                </li>
              </ul>
              <ContactFormGrey
                email={email}
                buttonText={buttonText}
                campaignId={campaignId}
                campaignVariant={campaignVariant}
                emailIsVisible={emailIsVisible}
                phoneIsVisible={phoneIsVisible}
              >
                {ctaText}
              </ContactFormGrey>
            </div>
            <div className="save-your-company-from-hackers-and-disasters-page__header-image">
              <Img
                title="A friendly male employee smiling."
                alt="A friendly male employee smiling. He is middle-aged, greying, has a button-up pin-striped shirt rolled to the forearms, a watch, jeans, and his arms are crossed in a friendly manner."
                fixed={friendlyPic}
              />
            </div>
          </div>
        </div>
      </header>
      <section className="save-your-company-from-hackers-and-disasters-page__reviews">
        <div className="save-your-company-from-hackers-and-disasters-page__content">
          <div className="save-your-company-from-hackers-and-disasters-page__review-content">
            <div className="save-your-company-from-hackers-and-disasters-page__review-image">
              <Img
                title="Daniel Pike, CEO Votre Allure"
                alt="Headshot of Daniel Pike, CEO Votre Allure. He is wearing a white button-up shirt with sunglasses dangling from the center."
                fixed={reviewHeadshot}
              />
            </div>
            <div className="save-your-company-from-hackers-and-disasters-page__review-text">
              <p className="paragraph">
                <em>Trust is the #1 requisite when selecting a provider</em>,
                and it has been a delight to work as a startup with Specter and
                watch them build an I.T. infrastructure for us in such a quick
                time that will scale with us as we grow.{" "}
                <em>Their professionalism is why we continue to be happy</em>{" "}
                with the service.
              </p>
              <p className="paragraph">- Daniel Pike, CEO Votre Allure LLC.</p>
            </div>
          </div>
        </div>
      </section>
      {children}
      <footer className="save-your-company-from-hackers-and-disasters-page__footer">
        <p className="paragraph">
          &copy; {new Date().getFullYear()} {specterLegalName} DBA{" "}
          {specterDbaName}
        </p>
      </footer>
    </Layout>
  )
}

SaveYourCompanyFromHackersAndDisastersCore.propTypes = {
  children: PropTypes.node.isRequired,
  email: PropTypes.string.isRequired,
  ctaText: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignVariant: PropTypes.string.isRequired,
}

export default SaveYourCompanyFromHackersAndDisastersCore

const query = graphql`
  query {
    specterData: site {
      siteMetadata {
        company {
          name {
            dba
            legal
          }
        }
      }
    }
    specterLogo: file(relativePath: { eq: "sts-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    friendlyPic: file(
      relativePath: {
        eq: "infopage/save-your-company-from-hackers-and-disasters/friendly-pic.png"
      }
    ) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    reviewHeadshot: file(
      relativePath: {
        eq: "infopage/save-your-company-from-hackers-and-disasters/daniel-pike-headshot.jpg"
      }
    ) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
