import React, { useState } from "react"
import PropTypes from "prop-types"

import ReactGA from "react-ga"

import { graphql, useStaticQuery } from "gatsby"

import * as EmailValidator from "email-validator"

import { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input"

const defaultState = {
  name: {
    isValid: false,
    isTouched: false,
    value: "",
  },
  email: {
    isValid: false,
    isTouched: false,
    value: "",
  },
  phone: {
    isValid: false,
    isTouched: false,
    value: "",
  },
}

export const ContactFormGrey = ({
  children,
  email,
  buttonText,
  campaignId,
  campaignVariant,
  phoneIsVisible,
  emailIsVisible,
}) => {
  const [formState, setFormState] = useState(defaultState)

  const data = useStaticQuery(query)
  const defaultEmail = data.site.siteMetadata.company.contact.email
  const siteThankYouUrl = data.site.siteMetadata.siteThankYouUrl

  const campaign = (campaignId + "__" + campaignVariant).toUpperCase()

  const utm = {
    source: "Form",
    medium: "Organic",
    campaign: campaign,
  }

  const redirectUrl = `${siteThankYouUrl}?utm_source=${utm.source}&utm_medium=${utm.medium}&utm_campaign=${utm.campaign}`

  const handleFormSubmit = () => {
    const submissionDetails = {
      category: "contact",
      action: "submit",
      label: campaign,
      value: 50,
    }
    ReactGA.event(submissionDetails)
  }

  const handleNameValidation = e => {
    var name = e.target.value.trim().split(" ")
    const hasTwoOrMoreWords = name.length >= 2
    let eachWordHasTwoOrMoreLetters = true
    name.forEach(n => {
      if (n.length < 2) {
        eachWordHasTwoOrMoreLetters = false
      }
    })
    const isvalid = hasTwoOrMoreWords && eachWordHasTwoOrMoreLetters
    setFormState({
      name: { value: e.target.value, isTouched: true, isValid: isvalid },
      email: formState.email,
      phone: formState.phone,
    })
  }

  const handleEmailValidation = e => {
    const isValidEmail = EmailValidator.validate(e.target.value.trim())
    setFormState({
      name: formState.name,
      email: { value: e.target.value, isTouched: true, isValid: isValidEmail },
      phone: formState.phone,
    })
  }

  const handlePhoneValidation = e => {
    let phoneNumber = e.target.value.trim()
    const isValidNumber = isValidPhoneNumber("+1" + phoneNumber)

    if (isValidNumber) {
      phoneNumber = "+1 " + formatPhoneNumber("+1" + phoneNumber)
    }

    setFormState({
      name: formState.name,
      email: formState.email,
      phone: {
        value: phoneNumber,
        isTouched: true,
        isValid: isValidNumber,
      },
    })
  }

  const formInputIsInvalid = formElement => {
    return (
      (!formElement.isValid && formElement.isTouched) ||
      (formElement.isValid && !formElement.isTouched)
    )
  }

  const appendValidationErrorPresentIfInvalid = formInput => {
    return formInputIsInvalid(formInput)
      ? " contact-form-grey__validation-error--present"
      : ""
  }

  return (
    <form
      action={"https://formspree.io/" + (email || defaultEmail)}
      method="POST"
      className="contact-form-grey"
    >
      <div className="contact-form-grey__fields">
        <input name="_next" type="hidden" value={redirectUrl} />
        <input name="campaign_id" type="hidden" value={campaign} readOnly />
        <div
          className={
            "contact-form-grey__validation-error" +
            appendValidationErrorPresentIfInvalid(formState.name)
          }
        >
          Please enter your name. Ex: John Wayne
        </div>
        <input
          onChange={e => handleNameValidation(e)}
          name="name"
          type="text"
          placeholder="First Last"
          value={formState.name.value}
          required
        />
        <div
          className={
            "contact-form-grey__validation-error" +
            appendValidationErrorPresentIfInvalid(formState.email)
          }
        >
          Please enter your email. Ex: john.wayne@domain.com
        </div>
        <input
          onChange={e => handleEmailValidation(e)}
          name="email"
          type={emailIsVisible ? "email" : "hidden"}
          placeholder="Email@Domain.com"
          value={formState.email.value}
          required
        />
        <div
          className={
            "contact-form-grey__validation-error" +
            appendValidationErrorPresentIfInvalid(formState.phone)
          }
        >
          Please enter a valid US phone number. E.g. (555) 555-5555.
        </div>
        <input
          onChange={e => handlePhoneValidation(e)}
          name="phone"
          type={phoneIsVisible ? "tel" : "hidden"}
          placeholder="(555) 555-5555"
          value={formState.phone.value}
          required
        />
        <button
          onClick={handleFormSubmit}
          className="button button--primary button--primary contact-form-grey__button"
          disabled={
            !formState.name.isValid ||
            !formState.email.isValid && emailIsVisible ||
            !formState.phone.isValid && phoneIsVisible
          }
        >
          {buttonText}
        </button>
      </div>
      <div className="contact-form-grey__cta-text">
        <h2 className="heading-secondary">{children}</h2>
      </div>
    </form>
  )
}

ContactFormGrey.propTypes = {
  children: PropTypes.node.isRequired,
  email: PropTypes.string,
  emailIsVisible: PropTypes.bool.isRequired,
  phone: PropTypes.string,
  phoneIsVisible: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  campaignVariant: PropTypes.string.isRequired,
}

export default ContactFormGrey

const query = graphql`
  query {
    site {
      siteMetadata {
        siteThankYouUrl
        company {
          contact {
            email
            phone
          }
        }
      }
    }
  }
`
